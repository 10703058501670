import { AppLayout, Box, ContentLayout, Header, SpaceBetween, Tabs, Toggle } from '@cloudscape-design/components';
import { applyMode, Mode } from '@cloudscape-design/global-styles';
import "@cloudscape-design/global-styles/index.css";
import { useState } from "react";
import ListingCards from './components/listing-cards';
import moonimg from './resources/moon.svg';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);

  applyMode(darkMode ? Mode.Dark : Mode.Light);

  return (
    <AppLayout
      contentType="cards"
      toolsHide
      navigationHide
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h1"
              actions={
                <Box margin={{ top: 's', right: 'm' }}>
                  <SpaceBetween size="xs" direction='horizontal'>
                    <Toggle
                      onChange={({ detail }) => {
                        setDarkMode(detail.checked)
                      }
                      }
                      checked={darkMode}
                    />
                    <Box margin={{ top: 'xxs' }}>
                      <img src={moonimg} alt='Dark Mode' />
                    </Box>
                  </SpaceBetween>
                </Box>}
            >{'SunsetFren'}</Header>
          }
        >
          <Tabs
            tabs={[
              {
                label: 'Main Collection',
                id: 'standard',
                content: (<ListingCards collection='solsunsets' />)
              },
              {
                label: 'Special Edition',
                id: 'specialedition',
                content: (<ListingCards collection='solsunsets_special_editions' />)
              }
            ]}
          />
        </ContentLayout>}
    />
  );
};

export default App;
