import axios, { AxiosRequestConfig } from 'axios';

const fetchData = async <T>(options: AxiosRequestConfig): Promise<T> => {
    options.headers = {
        ...options.headers,
    };

    const { data } = await axios(options).catch(function (error: Error) {
        console.log(error);
        throw new Error('there was an error while trying to retrieve data');
    });

    return data;
};

export const httpApiGet = async <T>([urlPath, queryParams]:[string, string]): Promise<T> => {
    const options = {
        method: 'GET',
        url: `${urlPath}${queryParams}`,
    };
    return await fetchData(options);
};
