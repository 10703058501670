import { Box, Cards, Container, ExpandableSection, Grid, Header, Link, Pagination, Select, SpaceBetween } from '@cloudscape-design/components';
import { useState } from 'react';
import { ListingDTO, useListings } from '../api/listings';
import meLogo from '../resources/me_logo.png';
import solanartLogo from '../resources/solanart_logo.png';
import tensorLogo from '../resources/tensor_logo.svg';
import hyperLogo from '../resources/hyperspace_logo.jpeg';

const LAMPORT_PER_SOL = 1000000000;

export interface ListingProps {
    collection: string;
}

export default function ListingCards(props: ListingProps) {
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const pageTokens = ['', '', '', '', '', '', '', '', '', ''];
    const [sortBy, setSortBy] = useState({ label: "Sort by Price: Low to High", value: "PriceAsc" });
    const [pageSize, setPageSize] = useState({ label: "100 results per page", value: "100" });
    const [queryParams, setQueryParams] = useState(`?collection=${props.collection}`)
    const listings = useListings(queryParams);

    return (
        <Cards
            cardDefinition={{
                header: item => item.name,
                sections: [
                    {
                        id: "images",
                        content: (item) => (
                            <SpaceBetween direction="horizontal" size="xs">

                                <SpaceBetween direction="vertical" size="s">
                                    {/* // button to expand banner to full size */}
                                    <img width={'100%'} height="auto" src={item.banner} alt='banner' />
                                    <Container>
                                        <SpaceBetween direction="vertical" size="xs">
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <strong>{`◎${item.listingPrice / LAMPORT_PER_SOL}`}</strong>
                                                <Box margin={{ top: 'xxxs' }}>
                                                    <img src={getMarketLogo(item)} width='18px' height='18px' alt='marketplace' />
                                                </Box>
                                            </SpaceBetween>
                                            <Link
                                                external
                                                externalIconAriaLabel="Opens in a new tab"
                                                href={getMarketLink(item)}
                                            >
                                                {'Buy Now'}
                                            </Link>
                                        </SpaceBetween>
                                    </Container>
                                    <ExpandableSection headerText="Details">
                                    <SpaceBetween direction="horizontal" size="s">

                                        <Grid
                                            gridDefinition={[{ colspan: 4 }, { colspan: 8 }]}
                                        >
                                            <img width={'100%'} height="auto" src={item.imageUri} alt='4:3' />

                                            <Cards
                                                cardDefinition={{
                                                    header: attr => attr.value,
                                                    sections: [
                                                        {
                                                            id: 'attrname',
                                                            content: attr => <small>{attr.trait_type}</small>
                                                        }
                                                    ]
                                                }}
                                                trackBy="trait_type"
                                                cardsPerRow={[
                                                    { minWidth: 800, cards: 4 },
                                                    { minWidth: 100, cards: 1 }
                                                ]}
                                                items={item.attributes}
                                                visibleSections={[
                                                    "attrname",
                                                ]}
                                            />
                                        </Grid>
                                    </SpaceBetween>
                                    </ExpandableSection>
                                </SpaceBetween>

                            </SpaceBetween>

                        )
                    },
                ]
            }}
            loading={listings.isLoading}
            cardsPerRow={[
                { minWidth: 500, cards: 1 },
                { minWidth: 400, cards: 2 }
            ]}
            items={listings.data?.listings ?? []}
            loadingText="Waiting for the sunset"
            stickyHeader
            trackBy="name"
            visibleSections={[
                "images",
            ]}
            empty={
                <Box textAlign="center" color="inherit">
                    <b>No listings found</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        ¯\_(ツ)_/¯
                    </Box>
                </Box>
            }
            header={
                <Header
                    actions={
                        <SpaceBetween direction='horizontal' size='s'>
                            <Select
                                selectedOption={sortBy}
                                onChange={({ detail }) => {
                                    setSortBy({ label: detail.selectedOption.label!, value: detail.selectedOption.value! })
                                    setQueryParams(`?collection=${props.collection}&sortBy=${detail.selectedOption.value}&limit=${pageSize.value}`);
                                    setCurrentPageIndex(1);
                                }
                                }
                                options={[
                                    { label: "Sort by Price: Low to High", value: "PriceAsc" },
                                    { label: "Sort by Price: High to Low", value: "PriceDesc" },
                                    { label: "Sort by Recently Listed", value: "ListedDesc" },
                                    { label: "Sort by Rare to Common", value: "RankHrttAsc" },
                                    { label: "Sort by Common to Rare", value: "RankHrttDesc" },
                                ]}
                                selectedAriaLabel="Selected"
                            />
                            <Select
                                selectedOption={pageSize}
                                onChange={({ detail }) => {
                                    setPageSize({ label: detail.selectedOption.label!, value: detail.selectedOption.value! })
                                    setQueryParams(`?collection=${props.collection}&sortBy=${sortBy.value}&limit=${detail.selectedOption.value}`);
                                    setCurrentPageIndex(1);
                                }
                                }
                                options={[
                                    { label: "10 results per page", value: "10" },
                                    { label: "25 results per page", value: "25" },
                                    { label: "50 results per page", value: "50" },
                                    { label: "100 results per page", value: "100" },
                                ]}
                                selectedAriaLabel="Selected"
                            />
                        </SpaceBetween>
                    }
                >
                    SolSunsets Active Listings
                </Header>}
            pagination={<Pagination
                ariaLabels={{
                    nextPageLabel: "Next page",
                    previousPageLabel: "Previous page",
                    pageLabel: (pageNumber: any) =>
                        `Page ${pageNumber} of all pages`
                }}
                currentPageIndex={currentPageIndex}
                onChange={({ detail }) => {
                    if (detail.currentPageIndex < currentPageIndex) {
                        setQueryParams(`?collection=${props.collection}&sortBy=${sortBy.value}&limit=${pageSize.value}&txKey=${pageTokens[detail.currentPageIndex - 1]}`);
                    } else if (detail.currentPageIndex > currentPageIndex) {
                        pageTokens[detail.currentPageIndex] = listings.data?.endCursor ?? '';
                        setQueryParams(`?collection=${props.collection}&sortBy=${sortBy.value}&limit=${pageSize.value}&txKey=${listings.data?.endCursor}`);
                    }
                    setCurrentPageIndex(detail.currentPageIndex);
                }
                }
                openEnd={listings.data?.hasMore}
                pagesCount={currentPageIndex}
            />}
        />
    )
}

const getMarketLink = (item: ListingDTO): string => {
    switch (item.source) {
        case 'TENSORSWAP':
            return `https://www.tensor.trade/item/${item.onChainId}`;
        case 'HYPERSPACE':
            return `https://hyperspace.xyz/token/${item.onChainId}`;
        case 'SOLANART':
            return `https://solanart.io/nft/${item.onChainId}`;
        case 'MAGICEDEN_V2':
        default:
            return `https://magiceden.io/item-details/${item.onChainId}`;
    }
}

const getMarketLogo = (item: ListingDTO) => {
    switch (item.source) {
        case 'TENSORSWAP':
            return tensorLogo;
        case 'HYPERSPACE':
            return hyperLogo;
        case 'SOLANART':
            return solanartLogo;
        case 'MAGICEDEN_V2':
        default:
            return meLogo;
    }
}