import useSWR from 'swr';
import { httpApiGet } from './apiSupport'

export interface Attribute {
    trait_type: string,
    value: string,
}

export interface LastSaleInfo {
    price: number
}

export interface ListingsDTO {
    listings: ListingDTO[],
    endCursor: string,
    hasMore: boolean,
    sortBy: string,
}

export interface ListingDTO {
    name: string,
    onChainId: string,
    imageUri: string,
    banner: string,
    metadataUri: string,
    attributes: Attribute[],
    lastSale: LastSaleInfo,
    listingPrice: number,
    source: string,
}
export interface DeaSingleResult<T> {
    data: T | undefined;
    isLoading: boolean;
    error: unknown;
}

export const useListings = (queryParams: string): DeaSingleResult<ListingsDTO> => {
    const {data, isLoading, error} = useSWR([`${process.env.REACT_APP_API_URL}/sunsets`, queryParams], httpApiGet<ListingsDTO>)

    return {
        data,
        isLoading,
        error,
    };
}
